import React, { Fragment } from "react";
import {
  List,
  Datagrid,
  Filter,
  TextInput,
  TextField,
  EditButton,
  BulkDeleteButton,
  DeleteButton,
  FunctionField,
} from "react-admin";
import { VideoTypeSelectInput } from "../../UI/Input/SelectInput";
import useCheckPermissions from '../AdminPermissions/useCheckPermissions'

const ListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
   
  </Filter>
);
const ListActionButtons = ({ ...props }) => {
  return (
    <>
      <BulkDeleteButton
        {...props}
        undoable={false}
        confirmTitle="Delete File Record"
        confirmContent={"Are you sure you want to delete this record?"}
      />
    </>
  );
};
const BasicSettingsList = ({ ...props }) => {
  return (
    <List
      {...props}
      title="Basic Plan"
      bulkActionButtons={<ListActionButtons />}
      filters={<ListFilter />}
     
    >
      <Datagrid rowClick={"show"}>
      <TextField source="id" />
      <TextField source="total_user_count_admin" label="User Count" />
      <TextField source="waiting_video_id" label="Waiting page Video Id" />
            {/* <TextField source="userwallet_min_amount" label="User Wallet Min Amount" />
            <TextField source="videowallet_min_amount" label="Video Wallet Min Amount" />
            <TextField source="admincharge" label="Admin Charge" />
            <TextField source="basic_user_refer_amount" label="Basic User Refer Amont" />
            <TextField source="primium_user_refer_amont" label="Primium User Refer Amont" /> */}
           
        {useCheckPermissions("BasicSetting_View")?<EditButton />:null}
        {useCheckPermissions("BasicSetting_Delete")?<DeleteButton />:null}
      </Datagrid>
    </List>
  );
};

export default BasicSettingsList;

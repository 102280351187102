import React, { Fragment } from 'react'
import {
    List, Datagrid, Filter, ShowButton, UrlField,Button, TextInput, TextField, EditButton, BulkDeleteButton, FunctionField, DeleteButton, useRecordContext, Link
} from 'react-admin'


const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        
    </Filter>
)
const ListActionButtons = ({ ...props }) => {
    return (<>
        <BulkDeleteButton {...props} undoable={false}
            confirmTitle="Delete File Record"
            confirmContent={"Are you sure you want to delete this record?"} />
    </>)
}
const ProfileidFiled = (props)=>{
    const record = useRecordContext();  
    return (
    <TextField {...props} component={Link} to={`/user?filter={"q":"${record.profile_id}"}`} onClick={(event)=>event.stopPropagation()} />
  )}


const UserSearchList = ({ ...props }) => {

    return (
        <List {...props} title="Microtask" bulkActionButtons={<ListActionButtons />} filters={<ListFilter />} sort={{ field: 'title', order: 'ASC' }}>

            <Datagrid >
            <TextField source="id" />
                <ProfileidFiled source="profile_id" label="Profile Id" />  
                <TextField source='name' label="Name" />
                <TextField source="team_type" label="Team Type" />
                <TextField source="task_type" label="Task Type" />
                <TextField source='created_at' label="Date" />
            </Datagrid>

        </List>
    )
}


export default UserSearchList
import React,{Fragment} from "react";
import {
  List,
  Datagrid,
  Filter,
  EditButton,
  Button,useRecordContext,
  TextInput,
  TextField,
  FunctionField,
  ShowButton,
  useListContext ,
  TopToolbar,
  DateInput
} from "react-admin";
import { DateRangeInput } from '../../UI/Input/input';
import DownloadIcon from '@material-ui/icons/GetApp';
import { stringify } from 'query-string';
import {UserStatusSelectInput,PlanSelectInput,JoinTypeSelectInput} from '../../UI/Input/SelectInput'
import useCheckPermissions from '../AdminPermissions/useCheckPermissions'
const ListFilter = (props) => {
  const {
    
    setFilters,
    hideFilter
} = useListContext();
  return (  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />    
    <UserStatusSelectInput label="Status" source="status" alwaysOn/>
    {/* <UserSelectInput label="Sponsor" source="profile_id" alwaysOn/> */}
    <TextInput label="Sponsor id" source="sponsor_id" alwaysOn />  
    <TextInput label="UTR Number" source="utr" alwaysOn />  
    <JoinTypeSelectInput label="Join Type" source="join_type" alwaysOn/>
    <PlanSelectInput label="Plan" source="plan" alwaysOn/>
    <DateInput source="current_date" lable="Date" alwaysOn/>
    <DateRangeInput alwaysOn onClear={() => { hideFilter('daterange') }} onChange={(strdate) => {
            setFilters({ daterange: strdate })
        }} />
  </Filter>)
};
const DownloadInvoice = (props) => {
  const record = useRecordContext();
  var itemid=(record&&record.id>0)?record.id:0;
  return <Button label="Download" href={`//sboportal.org.in/admin/api/v1/downloaduserinvoice?itemid=${itemid}`} size="small"><DownloadIcon /></Button>
}
const ListActions = (props) => {
   const record = useListContext();
  const filva = record.filterValues ? stringify(record.filterValues) : ''; 
  
  return (
  <TopToolbar>
     <Button  href={`//sboportal.org.in/admin/api/v1/user/export?${filva}`} label="User Export" ><DownloadIcon /></Button>
     </TopToolbar >)
};

const UserList = ({ record,...props }) => {
 
  return (
    <Fragment>
    <List
     {...props}
      title="User"
      bulkActionButtons={null}
      
      sort={{ field: "name", order: "ASC" }}
      actions={<ListActions/>}
      filters={<ListFilter />}
    >
      <Datagrid >
        <TextField source="id" />
        <TextField source="name" label="Name" />
        <TextField source="profile_id" label="Profile ID" />
        <TextField source="user_sponsorid" label="Sponserid" />
        <TextField source="join_type" label="Join Type" />          
        <TextField source="email" label="Email" />  
        <TextField source="created_at" label="Joining Date" />
        <TextField source="utr" label="UTR Number" />        
        <FunctionField source="status" label="Status" render={record => {  
          if (record['status'] === 'Decline') {
            return ( <Button style={{ color:'red',border:'1px solid'}} label="Decline" size="small"></Button>)
          } else if(record['status'] === 'Pending') {
            return ( <Button style={{ color:'orange',border:'1px solid'}} label="Pending" size="small"></Button>)            
          }else if(record['status'] === 'Active'){
            return ( <Button style={{ color:'green',border:'1px solid'}} label="Active" size="small"></Button>)
          }else if(record['status'] === 'Suspended'){
            return ( <Button style={{ color:'blue',border:'1px solid'}} label="Suspended" size="small"></Button>)
          } else if(record['status'] === 'Blocked'){
            return ( <Button style={{ color:'purple',border:'1px solid'}} label="Blocked" size="small"></Button>)
          }                         
          }} />
          {useCheckPermissions('User_Edit')?<EditButton/>:null}
          {useCheckPermissions('User_View')?<ShowButton/>:null}
          {useCheckPermissions('User_Download')?<DownloadInvoice label="Download" />:null}
      </Datagrid>
    </List>
      
       </Fragment>
  );
};

export default UserList;
